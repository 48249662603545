exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compass-js": () => import("./../../../src/pages/compass.js" /* webpackChunkName: "component---src-pages-compass-js" */),
  "component---src-pages-fitara-js": () => import("./../../../src/pages/fitara.js" /* webpackChunkName: "component---src-pages-fitara-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobitv-js": () => import("./../../../src/pages/mobitv.js" /* webpackChunkName: "component---src-pages-mobitv-js" */),
  "component---src-pages-seyond-js": () => import("./../../../src/pages/seyond.js" /* webpackChunkName: "component---src-pages-seyond-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

